<div>
    <div style="
    padding: 5px;
">
        <button mat-raised-button color="primary" style="margin-right: 1%;grid-column: 12;"
            (click)="onAddingFeedback()">
            Add feedback
        </button>
        <button mat-raised-button color="primary" style="margin-right: 1%;grid-column: 12;" *ngIf="this.selection.selected.length"
            (click)="onDeleteFeedback()">
            Delete feedback(s)
        </button>
    </div>
    <br />
    <mat-card>
        <span>Product Status</span>
        <div>
            <table mat-table [dataSource]="rowData">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}}</td>
                </ng-container>

                <ng-container matColumnDef="feedback">
                    <th mat-header-cell *matHeaderCellDef> Feedback </th>
                    <td mat-cell *matCellDef="let element"> {{element.feedback}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}}</td>
                </ng-container>

                <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef> From </th>
                    <td mat-cell *matCellDef="let element"> {{element.from}}</td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.moduser}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 20, 50]" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </mat-card>
    <br />
    <mat-card >
        <div>
            <span>Package Status</span>
            <table #packagetable="matTable" mat-table [dataSource]="packageTableRowData"  matSort
            #empTbSort="matSort">
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let element"> {{element.date}}</td>
                </ng-container>

                <ng-container matColumnDef="feedback">
                    <th mat-header-cell *matHeaderCellDef> Feedback </th>
                    <td mat-cell *matCellDef="let element"> {{element.feedback}}</td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}}</td>
                </ng-container>

                <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef> From </th>
                    <td mat-cell *matCellDef="let element"> {{element.from}}</td>
                </ng-container>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.moduser}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="packageDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: packageDisplayedColumns;"></tr>
            </table>
            <mat-paginator #packagePaginator="matPaginator" [pageSizeOptions]="[5, 20, 50]" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </mat-card>
</div>
