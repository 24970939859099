import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { itemService } from 'src/app/helpers/services/main.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { FeedbackDialogComponent } from './feedback_dialog/feedback_dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableModule } from '@angular/material/table';
import { ItemDetailService } from '../../../helpers/services/item_detail.service'
import { MatSort, Sort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'license-tracking',
  templateUrl: './license-tracking.component.html',
  styleUrls: ['./license-tracking.component.scss']
})
export class LicenseTrackingComponent implements OnInit {
  @Input() showLatest: boolean
  @Input() disablePagination: boolean
  @Input() gridClass: string;
  @Input() itemDetailId: number
  @Input() itemData: any
  @Input() selectedLicensor: any
  @ViewChild('empTbSort') empTbSort = new MatSort();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('packagePaginator') packagePaginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>
  @ViewChild('packagetable') packagetable: MatTable<any>
  rowData: any;
  packageTableRowData: any = [];
  displayedColumns: string[] = ['select','date', 'feedback', 'status', 'from', 'user'];
  packageDisplayedColumns: string[] = ['select','date', 'feedback', 'status', 'from', 'user'];
  moduleName: string = "licensing_tracking"
  auditlog: any
  packageData: any[] = []
  selection = new SelectionModel(true, []);
  columnsOfInterest = ['comment', 'item_desc', 'concept_submiteed', 'concept_approved',
    'concept_rejected', 'sample_requested', 'sample_made', 'vendor_sent',
    'office_received', 'office_sent', 'dev_received', 'dev_sample_sent',
    'prepo_approved', 'prepo_rejected', 'order_placed', 'sample_no'
  ];

  latestValues = {};

  constructor(public itemService: itemService, public dialog: MatDialog, public itemDetailService: ItemDetailService, private _snackBar: MatSnackBar) { }

  async ngOnInit() {
     // await this.itemService.getAuditLogByItemDetailId(this.itemDetailId, this.moduleName).subscribe(auditlog => {
     //     if (this.showLatest) {
     //         //Initialize latestValues with null values for all columns of interest
     //         this.columnsOfInterest.forEach(columnName => {
     //             this.latestValues[columnName] = null
     //         });

      //        auditlog['data'].forEach(item => {
      //            // Check if the item's element_id is in columnsOfInterest and actionDate is newer
      //            if (this.columnsOfInterest.includes(item.element_id) &&
      //                (!this.latestValues[item.element_id] || item.actionDate > this.latestValues[item.element_id].actionDate)) {
      //               this.latestValues[item.element_id] = item.newValue;
      //           }
      //        });
      //    } else {
      //        this.rowData = new MatTableDataSource(auditlog['data'])
      //        console.log(auditlog['data'])
      //        this.rowData.paginator = this.paginator;
      //    }
      // })
    
    let href = document.location.href.split('/')
      this.itemDetailService.GetLicensingStatus(href[href.length - 1]).subscribe(data=> this.updateRowData(data))
  }

  updateRowData(data){
    window["packageData"] = [];
    window["data"] = [];
    for (let i = 0; data.length > i; i++) {
      if (data[i].package) {
        window["packageData"].push(data[i]);
        this.packageData.push(data[i])
    } else {
        window["data"].push(data[i]);
      }
    }
    window["packageTableRowData"] = this.packageTableRowData
    window["packagePaginator"] = this.packagePaginator
    this.packageTableRowData = new MatTableDataSource(window["packageData"])
    window["packageTableRowData"] = this.packageTableRowData
    console.log(window["packageTableRowData"])
    window["packageTableRowData"].paginator = this.packagePaginator;
    window["packagetable"] = this.packagetable;
    /////////
    window["rowData"] = this.rowData
    window["paginator"] = this.paginator
    this.rowData = new MatTableDataSource(window["data"])
    window["rowData"] = this.rowData
    console.log(window["data"])
    window["rowData"].paginator = this.paginator;
    window["table"] = this.table;
  }
  //   })

  onAddingFeedback() {
    console.log(this.itemData)
    window["data"].selectedLicensor = this.selectedLicensor
    window["data"].udf_freeform_01 = this.itemData.udf_freeform_01
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      disableClose: true,
      width: '550px', // Adjust the width as needed
      data: window["data"]
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        result.data.moduser = result.data.username;
        result.data.date = result.data.actionDate
        console.log(`Dialog result: ${result}`);
        if (result.data.package) {
          console.log(`window["packageTableRowData"]: ${window["packageTableRowData"]}`);
          //window["packageTableRowData"].data.push(result.data)
          window["packageData"].push(result.data);
          this.packageData.push(result.data)
          console.log(window["data"])
          window["packagetable"].renderRows();
          window["packageTableRowData"].paginator = window["packagePaginator"]
        } else {
          console.log(`window["rowData"]: ${window["rowData"]}`);
          //window["rowData"].data.push(result.data)
          window["data"].push(result.data);
          console.log(window["data"])
          window["table"].renderRows();
          window["rowData"].paginator = window["paginator"]
        }

        // var rowNode = params.api.getRowNode(params.node.data[getMasterFiledName('price_code')])!;
        // params.node.data['duty_rate_dollar_amount'] = result.duty_rate_dollar_amount;
        // params.node.data['duty_rate_dialog'] = true;
        // rowNode.setDataValue(getMasterFiledName('duty_rate'), result.duty_rate);
  }
    });
}

onDeleteFeedback(){
  console.log(this.selection.selected)
  if(this.selection.selected.length){
    this.itemDetailService.DeleteLicensingStatus(this.selection.selected).subscribe((data) => {
      if(!data?.data) return this._snackBar.open('An error occurred while deleting', 'Close');
      this.selection.clear();
      this.updateRowData(data.data)
    }, () => {
      this._snackBar.open('An error occurred while deleting', 'Close');
    })
  }
}
}


