import { Injectable } from '@angular/core'
import { Observable, pipe } from 'rxjs'
import { HttpClient, HttpParams } from '@angular/common/http'
import { map, catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CommentInterface } from 'src/app/pages/comments/types/commentInterface';
@Injectable()
export class ItemDetailService {
  serverAddress = environment.item_master + '/detail';
  private comments: CommentInterface[] = [];

  constructor(private http: HttpClient) { }

  setUserAuth() {
    let token = localStorage.getItem("token")
    const headers = new HttpHeaders().set('Authorization', token)
    return headers
  }

  getItemInfo(id: any) {
    console.log(this.serverAddress + '/getItemInfo')
    return this.http.post<any>(`${this.serverAddress}/getItemInfo`, id, { headers: this.setUserAuth() })
  }

  setPrimaryImage(item: object) {
    return this.http.post(`${this.serverAddress}/setPrimaryImage`, item)
  }
  
  addComment(comment: CommentInterface) {
    return this.http.post<any>(`${this.serverAddress}/addComment`, comment, { headers: this.setUserAuth() })
  }

  getComments(id: number) {
    return this.http.get<any>(`${this.serverAddress}/getComments/${id}`, { headers: this.setUserAuth() })
  }

  updateComment(id: number, comment: string) {
    return this.http.post<any>(`${this.serverAddress}/updateComment/${id}`, { comment }, { headers: this.setUserAuth() })
  }

  updateData(data: any) {
    return this.http.post<any>(`${this.serverAddress}/updateData`, data, { headers: this.setUserAuth() })
  }

  getLicenseFeedBacks(data) {
    return this.http.post<any>(`${this.serverAddress}/getLicenseFeedBacks`, data, { headers: this.setUserAuth() })
  }

  AddLicensingStatus(data: any) {
    return this.http.post<any>(`${this.serverAddress}/AddLicensingStatus`, data, { headers: this.setUserAuth() })
  }

  BulkAddLicensingStatus(data: any) {
    return this.http.post<any>(`${this.serverAddress}/BulkAddLicensingStatus`, data, { headers: this.setUserAuth() })
  }
  
  DeleteLicensingStatus(data: any) {
    return this.http.post<any>(`${this.serverAddress}/DeleteLicensingStatus`, data, { headers: this.setUserAuth() })
  }

  GetLicensingStatus(id) {
    return this.http.get<any>(`${this.serverAddress}/GetLicensingStatus/${id}`, { headers: this.setUserAuth() })
  }

  BulkGetLicensingStatus(data) {
    return this.http.post<any>(`${this.serverAddress}/BulkGetLicensingStatus`, data, { headers: this.setUserAuth() })
  }

  reqStyeNumber(data: any) {
    return this.http.post<any>(`${this.serverAddress}/reqStyeNumber`, data, { headers: this.setUserAuth() })
  }
  
}

