import { Component, ViewChild, AfterViewInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { carouselImage } from '../../helpers/models/carouselImage.module'
import { itemService } from '../../helpers/services/main.service'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { dateFormat } from '../../helpers/models/dateFormat.module'
import { UntypedFormControl } from '@angular/forms';
import { GalleryItem, ImageItem, Gallery } from 'ng-gallery';
import { ItemDetailService } from '../../helpers/services/item_detail.service'
import { imageEditorComponent } from '../itemLibrary/imageEditor/imageEditor.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { UploadFileComponent } from '../rfq/upload-file/upload-file.component';
import { ViewUploadedFilesDialogComponent } from '../itemLibrary/view-uploaded-files-dialog/view-uploaded-files-dialog.component';
import { ViewNotesDialogComponent } from '../itemLibrary/view-notes-dialog/view-notes-dialog.component';

@Component({
  selector: "app-itemDetail",
  templateUrl: "./itemDetail.component.html",
  styleUrls: ["./itemDetail.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: dateFormat },
  ],
})
export class ItemDetailComponent implements AfterViewInit {
  techpacktDlBTN = false;
  powerpointDlBTN = false;
  editing: boolean = false;
  selectedMaterial: any = { title: null };
  selectedConstruction: any = { title: null };
  selectedFeature: any = { title: null };
  selectedPackageType: any = { title: null };
  selectedSize: any = { title: null };
  selectedLicensor: any = { title: null };
  selectedProperty: any = { title: null };
  selectedDivisonCode: any = { title: null };
  selectedFormat: any = { title: null };
  selectedArtContent: any = { title: null };
  artistOptions: any = [];
  materialOptions: any = [];
  constructionOptions: any = [];
  packageTypeOptions: any = [
    "Cardboard corners",
    "Traybox",
    "PVC bag with J-card",
    "Hanger with J-card and polybag",
    "Polybag with hangtag",
    "Packaging sticker",
    "Phone stand packaging",
    "Tablet stand packaging",
  ];
  featureOptions: any = [];
  sizeOptions: any = [];
  licensorOptions: any = [];
  propertyOptions: any = [];
  formatOptions: any = [];
  selectedArtist: any = { title: null };
  artContentOptions: any = [];
  galleryId = "myLightbox";
  GalleryItems: GalleryItem[] = [];
  imageSliderObject: any[] = [];
  detailImages: any[] = [];
  artSourceOptions: any[] = [];
  seasonptions: any[] = [];
  detailThumbs: any[] = [];
  asyncTabs: any = [];
  attachments: any = [];
  itemData: any = [];
  dates: any = [];
  selectedProductManager: any = {};
  selectedVendor: any = [];
  UDFElement: any = [];
  salesperOption: any = ["Adam"];
  UDFElementGroup: any = [];
  selectedActiveStatus: any = [];
  selectedDiscountStatus: any = [];
  selectedSeason: any = [];
  selectedArtSource: any = [];
  productManagerOption = [];
  vendors: any[] = [];
  selectedSalesPerson: any = [];
  divisionOptions = [];
  yesNoOptions = [
    { title: "Yes", id: "Y" },
    { title: "No", id: "N" },
  ];
  mg: any = [];
  selected = false;
  itemDetailId: number;
  showCalendar: boolean = false;
  public files: any[] = [];
  isLoading = false;
  divisions = {1:"POP Lic", 2:"Spruce non-Lic", 7:"POP Lic", 8:"Spruce Lic", 9:"Spruce non-Lic"}
  @ViewChild("myGallery") myGallery: ElementRef | undefined;
  @ViewChild("myThumbsContainer") myThumbsContainer: ElementRef | undefined;
  constructor(
    public dialog: MatDialog,
    private itemService: itemService,
    public gallery: Gallery,
    public ItemDetailService: ItemDetailService,
    private _snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) { }

  async ngAfterViewInit() {
    this.cdr.detectChanges();
  }
  async onSelect(value) {
    return new Promise((res, rej) => {
      if (value != false) {
        this.selected = true;
      } else {
        this.selected = false;
      }
      res(this.selected);
    });
  }
  onSelectClosed() {
    this.selected = true;
  }
  async ngOnInit() {
    let href = document.location.href.split("/");
    this.itemDetailId = href[href.length - 1] as any;
    await this.itemService.getSeasonCode().subscribe(async (res: any) => {
      this.seasonptions = res.data;
    });
    console.log("Material");
    await this.itemService
      .getMerchGroup({ divisionCode: 1, DivisionStatus: 1 })
      .subscribe(async (data: any) => {
        console.log(data);
        for (var i = 0; data.length > i; i++) {
          for (var j = 0; data[i].merchGroupHeaders.length > j; j++) {
            if (data[i].merchGroupHeaders[j].mgTypeDesc == "Material") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.materialOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (
              data[i].merchGroupHeaders[j].mgTypeDesc == "Construction"
            ) {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.constructionOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Feature") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.featureOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Size") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.sizeOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Licensor") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.licensorOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Property") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.propertyOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (
              data[i].merchGroupHeaders[j].mgTypeDesc == "Sub Format"
            ) {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.formatOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (
              data[i].merchGroupHeaders[j].mgTypeDesc == "Art Content"
            ) {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.artContentOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (
              data[i].merchGroupHeaders[j].mgTypeDesc == "Style guide"
            ) {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                //   this.groupOptions.push({ title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc, id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id, mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code  })
              }
            } else if (
              data[i].merchGroupHeaders[j].mgTypeDesc == "Art Source"
            ) {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.artSourceOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            } else if (data[i].merchGroupHeaders[j].mgTypeDesc == "Artist") {
              for (
                var k = 0;
                data[i].merchGroupHeaders[j].merchGroups.length > k;
                k++
              ) {
                this.artistOptions.push({
                  title: data[i].merchGroupHeaders[j].merchGroups[k].mg_desc,
                  id: data[i].merchGroupHeaders[j].merchGroups[k].mg_id,
                  mg_code: data[i].merchGroupHeaders[j].merchGroups[k].mg_code,
                });
              }
            }
            this.productManagerOption = [
              { title: "Albert" },
              { title: "Adam" },
            ];
          }
        }
        await this.itemService.getCompanyCode().subscribe((data: any) => {
          this.divisionOptions = data;
        });

        let href = document.location.href.split("/");

        this.itemService
          .getUDFElements({ component: href[href.length - 2] })
          .subscribe((data) => {
            console.log(data);

            data.forEach((element) => {
              if (
                !this.UDFElementGroup.includes(element.UDFGroup.UDFGroup_name)
              ) {
                this.UDFElementGroup.push(element.UDFGroup.UDFGroup_name);
              }
            });

            this.UDFElement = data;
          });

        await this.itemService.getAllVendors().subscribe((vendors: any) => {
          this.vendors = vendors;
        });

        await this.ItemDetailService.getItemInfo({
          id: href[href.length - 1],
        }).subscribe((item) => {
          this.itemData = item;
          
          console.log(this.itemData[0].item_active_status);
          this.selectedMaterial = this.materialOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup01
          );
          this.selectedConstruction = this.constructionOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup02
          );
          this.selectedFeature = this.featureOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup03
          );
          this.selectedSize = this.sizeOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup04
          );
          this.selectedLicensor = this.licensorOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup05_fk
          );
          this.selectedProperty = this.propertyOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup06_fk
          );
          this.selectedFormat = this.formatOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup07_fk
          );
          this.selectedArtContent = this.artContentOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup08
          );
          this.selectedDivisonCode = this.divisionOptions.find(
            (x) => x.comCode_id == this.itemData[0].div_code_fk
          );
          this.selectedActiveStatus = this.yesNoOptions.find(
            (x) => x.id == this.itemData[0].item_active_status
          );
          this.selectedDiscountStatus = this.yesNoOptions.find(
            (x) => x.id == this.itemData[0].discont_status
          );
          this.selectedArtSource = this.artSourceOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup10_fk
          );
          this.selectedVendor = this.vendors.find(
            (x) => x.id == this.itemData[0].vendor_code_fk
          );
          this.selectedArtist = this.artistOptions.find(
            (x) => x.mg_code == this.itemData[0].udf_merchgroup09_fk
          );
          this.selectedSalesPerson = this.salesperOption.find(
            (x) => x == this.itemData[0].salesper_code_fk
          );
          this.selectedPackageType = this.packageTypeOptions.find(
            (x) => x == this.itemData[0].udf_freeform_06
          );
          this.selectedProductManager = this.productManagerOption.find(
            (x) => x.title == this.itemData[0].productmanager
          );
          this.selectedSeason = this.seasonptions.find(
            (x) => x.seasonDesc == this.itemData[0].season_code_fk
          );
          this.itemService
            .getMG({
              division_name: item[0].compan_code_fk,
              company_name: item[0].div_code_fk,
            })
            .subscribe((data) => {
              this.mg = data;
              console.log(data);
            });
          console.log(this.itemData);
          this.dates.push(new UntypedFormControl(this.itemData[0].udf_date01));
          this.dates.push(new UntypedFormControl(this.itemData[0].udf_date02));
          this.dates.push(new UntypedFormControl(this.itemData[0].udf_date03));
          this.dates.push(new UntypedFormControl(this.itemData[0].udf_date05));
          this.dates.push(new UntypedFormControl(this.itemData[0].udf_date06));
          this.dates.push(
            new UntypedFormControl(this.itemData[0].created_time_date)
          );
          console.log(this.dates);
          this.itemData[0].royalty_code_fk = item[0].royalty_code_fk;
          this.itemData[0].mod_user_fk = item[0].mod_user_fk;
          /*      item[0].itemAttachments.forEach(element => {
        if (item[0].itemAttachments) {
          if (element.attachment_display_name != 'Thumb' && element.attachment_display_name != 'Image') {
            this.attachments.push(element)
          } else if (element.attachment_display_name == 'Thumb') {
            this.detailThumbs.push(element)
          } else if (element.attachment_display_name == 'Image') {
            this.detailImages.push(element)
          }
        }
      });*/
          /*      for (var i = 0; this.detailImages.length > i; i++) {
        this.GalleryItems.push(
          new ImageItem({ src: this.detailImages[i].attachment_link, thumb: this.detailThumbs[i].attachment_link })
        )
      }*/
          /*      this.gallery.ref().load(this.GalleryItems);
      this.gallery.ref().itemClick.subscribe((index) => {
        this.dialog.open(imageEditorComponent, {
          data: {
            FullSizePicture: this.detailImages[index].attachment_link
          }
        });
      })*/
        });

        this.asyncTabs = [
          { id: "0", label: "Main" },
          { id: "1", label: "Licensing" },
          //  { id: '2', label: 'Pricing' },
          //  { id: '3', label: 'Production' },
          { id: "2", label: "Calendar" },
          //{ id: '5', label: 'Item logs' },
          //{ id: '6', label: 'Item admin logs'},
        ];
      });
  }

  onItem() {
    window.location.href = "http://www.google.com";
  }

  tabChanged(tabId) {
    this.showCalendar = tabId === 2 ? true : false;
  }

  uploadFile(type) {
    let href = document.location.href.split("/");
    const itemDetailId = href[href.length - 1];
    const dialogRef = this.dialog.open(UploadFileComponent, {
      disableClose: true,
      data: {
        itemId: itemDetailId,
        value: type,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((message) => {
        if (message) {
          console.log(message);
          this._snackBar.open("File Uploaded Successfully", "ok", {
            duration: 3000,
          });
        }
      });
  }

  openViewUploadedFilesDialog = async (type) => {
    let href = document.location.href.split("/");
    const id = href[href.length - 1];
    const itemDetailsData = this.ItemDetailService.getItemInfo({
      id,
    }).toPromise();
    const itemDetails = await itemDetailsData;
    const attachments = itemDetails[0].itemAttachments;
    const dialogRef = this.dialog.open(ViewUploadedFilesDialogComponent, {
      data: {
        type,
        attachments,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((message) => {
        if (message) {
          console.log(message);
        }
      });
  };

  viewNotesDialog = async () => {
    let href = document.location.href.split("/");
    const itemId = href[href.length - 1];
    const dialogRef = this.dialog.open(ViewNotesDialogComponent, {
      width: "600px", // Adjust the width as needed
      height: "600px",
      data: {
        itemId: itemId,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((message) => {
        if (message) {
          console.log(message);
        }
      });
  };

  async onSave() {
    if (this.selectedMaterial)
      this.itemData[0].udf_merchgroup01 = this.selectedMaterial?.mg_code;
    if (this.selectedConstruction)
      this.itemData[0].udf_merchgroup02 = this.selectedConstruction?.mg_code;
    if (this.selectedFeature)
      this.itemData[0].udf_merchgroup03 = this.selectedFeature?.mg_code;
    if (this.selectedSize)
      this.itemData[0].udf_merchgroup04 = this.selectedSize?.mg_code;
    if (this.selectedLicensor)
      this.itemData[0].udf_merchgroup05_fk = this.selectedLicensor?.mg_code;
    if (this.selectedProperty)
      this.itemData[0].udf_merchgroup06_fk = this.selectedProperty?.mg_code;
    if (this.selectedFormat)
      this.itemData[0].udf_merchgroup07 = this.selectedFormat?.mg_code;
    if (this.selectedArtContent)
      this.itemData[0].udf_merchgroup08 = this.selectedArtContent?.mg_code;
    if (this.selectedDivisonCode)
      this.itemData[0].div_code_fk = this.selectedDivisonCode.comCode_id;
    if (this.selectedActiveStatus)
      this.itemData[0].item_active_status = this.selectedActiveStatus?.id;
    if (this.selectedDiscountStatus)
      this.itemData[0].discont_status = this.selectedDiscountStatus?.id;
    if (this.selectedArtSource)
      this.itemData[0].udf_merchgroup10_fk = this.selectedArtSource?.id;
    if (this.selectedVendor)
      this.itemData[0].vendor_code_fk = this.selectedVendor.id;
    if (this.selectedArtist)
      this.itemData[0].udf_merchgroup09_fk = this.selectedArtist.id;
    if (this.selectedSalesPerson)
      this.itemData[0].salesper_code_fk = this.selectedSalesPerson;
    if (this.selectedPackageType)
      this.itemData[0].udf_freeform_06 = this.selectedPackageType;
    if (this.selectedProductManager)
      this.itemData[0].productmanager = this.selectedProductManager?.title;
    if (this.selectedSeason)
      this.itemData[0].season_code_fk = this.selectedSeason.seasonCode;

    this.itemData[0].udf_date01 = this.dates[0].value;
    this.itemData[0].udf_date02 = this.dates[1].value;
    this.itemData[0].udf_date03 = this.dates[2].value;
    this.itemData[0].udf_date05 = this.dates[3].value;
    this.itemData[0].udf_date06 = this.dates[4].value;

    console.log(this.itemData[0]);
    await this.ItemDetailService.updateData(this.itemData[0]).subscribe(
      (res: any) => {
        if (res.statusCode === 200) {
          this._snackBar.open(`Changes were saved successfully.`, "close");
        } else {
          this._snackBar.open(`Something went wrong.`, "close");
        }
      }
    );
  }

  async onStyleNumber() {
    this.ItemDetailService.reqStyeNumber(this.itemData[0]).subscribe(
      async (res: any) => {
        if (res.statusCode === 200) {
          localStorage.removeItem('itemLibrary');
          this._snackBar.open(
            `Request was sent successfully style number is: ${res.itemNo}.`,
            "close"
          );
          await window["ItemLibraryService"].getAllitems().subscribe(async (data: any) => {
            window['params'].api.setRowData(data);
            data.push({ expirationTime: Date.now() });
            localStorage.setItem('itemLibrary', JSON.stringify(data));
          })
        } else {
          this._snackBar.open(`Something went wrong.`, "close");
        }
      }
    );
  }
}

